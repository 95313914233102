import { Link } from 'gatsby'
import React from 'react'

const SubHeader: React.FC = () => {
    return (
        <section className="mod-subheader">
            <div className="lay">
                <nav className="row">
                    <div className="com-dolar">
                        <Link
                            to={'/'}
                            title="Ir a notas de Dólar Hoy y Cotización"
                            className="com-link --fourxs --dolar"
                        >
                            Bitcoin:
                        </Link>
                        <span className=" com-text --fourxs">Ethereum:</span>
                        <span id="precioCompraBna" className="precioDolar --fourxs">
                            0,6
                        </span>
                        <Link
                            to={'/'}
                            title="Ir a notas de Dólar blue"
                            className="com-link --fourxs --blue"
                        >
                            BE:
                        </Link>
                        <span id="precioCompraBlue" className="precioDolar --fourxs">
                            0,1
                        </span>
                        <Link
                            to={'/'}
                            title="Ir a notas de Dólar blue"
                            className="com-link --fourxs --blue"
                        >
                            Dólar:
                        </Link>
                        <span id="precioCompraBlue" className="precioDolar --fourxs">
                            0,001
                        </span>
                        <Link
                            to={'/'}
                            title="Ir a notas de Dólar blue"
                            className="com-link --fourxs --blue"
                        >
                            Euro:
                        </Link>
                        <span id="precioCompraBlue" className="precioDolar --fourxs">
                            0,0011
                        </span>
                    </div>
                    <Link
                        to={'/'}
                        className="com-link --weather"
                    >
                        <div className="com-weather">
                            <i className="com-icon icon-sun-cloudy" />
                            <span id="spanTemperatura" className="--fourxs">
                                11.7°
                            </span>
                            <span id="spanPlace" className="--fourxs">
                                Capital Federal
                            </span>
                        </div>
                    </Link>
                    <Link
                        to={'/'}
                        title="Recibí Newsletters"
                        className="com-link --fourxs --newsletter"
                    >
                        Recibí Newsletters
                    </Link>
                    <Link
                        to={'/'}
                        title="Ir a Club LA NACION"
                        className="com-link --fourxs com-club"
                    >
                        <i className="com-logo logo-club --xs" />
                        Descubrí tus beneficos
                    </Link>
                </nav>
            </div>
        </section>
    )
}

export default SubHeader
