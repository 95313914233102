import { Link } from 'gatsby'
import React from 'react'
import Disclaimer from '../../Disclaimer'

interface PostSmallProps {
    mainImage,
    title,
    lead,
    authorName
    slug
}

const PostSmall: React.FC<PostSmallProps> = ({ mainImage, title, lead, authorName, slug }) => {
    return (
        <article className="mod-article">
            <div>
                <section role="button" className="mod-media">
                    <figure
                        role="button"
                        className="mod-figure --horizontal"
                    >
                        <Link to={`/${slug}`}>
                            <picture className="mod-picture">
                                <img
                                    src={mainImage.asset.url + '?auto=format&q=80'}
                                    alt={mainImage.asset.alt}
                                    width={768}
                                    height={513}
                                    className="com-image"
                                    loading="lazy"
                                />
                            </picture>
                        </Link>
                    </figure>
                </section>
            </div>
            <section className="mod-description">
                <h2 className="com-title --xs">
                    <Link
                        to={`/${slug}`}
                        className="com-link"
                    >
                        <em className="com-lead">{lead}</em>
                        {title}
                    </Link>
                </h2>
                <div>
                    <strong className="mod-marquee --fourxs">
                        <Link
                            to={`/${slug}`}
                            title={`Por ${authorName}`}
                        >
                            Por {authorName}
                        </Link>
                    </strong>
                </div>
            </section>
        </article>
    )
}

export default PostSmall
