import React from 'react'
import PostLarge from '../PostLarge/PostLarge';
import PostSmall from '../PostSmall';
const BlockContent = require('@sanity/block-content-to-react')

interface PostsHomeProps {
    posts: any
}

const PostsHome: React.FC<PostsHomeProps> = ({ posts }) => {
    return (
        <div>
            <div>
                <section
                    className="box-articles --focal --left --apertura"
                >
                    <div className="row">
                        <div className="col-tablet-8">
                            <PostLarge
                                mainImage={posts[0].mainImage}
                                title={posts[0].title}
                                lead={posts[0].lead}
                                excerpt={posts[0]._rawExcerpt}
                                authorName={posts[0].author.name}
                                slug={posts[0].slug.current}
                            />
                        </div>
                        <div className="col-tablet-4">
                            {
                                posts.map((post, i) => {
                                    if (i === 1 || i === 2) {
                                        return <PostSmall
                                            key={i}
                                            mainImage={post.mainImage}
                                            title={post.title}
                                            lead={post.lead}
                                            authorName={post.author.name}
                                            slug={post.slug.current} />
                                    }
                                })
                            }
                        </div>
                    </div>
                </section>
            </div>
            <div className="mod-banner  --caja1_mob hlp-none">
                <div id="caja1_mob" className="com-banner" />
            </div>
            <div>
                <section
                    className="box-articles"
                >
                    <div className="row-gap-tablet-3">
                        {
                            posts.map((post, i) => {
                                if (i > 2) {
                                    return <PostSmall
                                        key={i}
                                        mainImage={post.mainImage}
                                        title={post.title}
                                        lead={post.lead}
                                        authorName={post.author.name}
                                        slug={post.slug.current} />
                                }
                            })
                        }
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PostsHome
