import { Link } from 'gatsby'
import React from 'react'
import Disclaimer from '../../Disclaimer'
const BlockContent = require('@sanity/block-content-to-react')

interface PostLargeProps {
    mainImage,
    title,
    lead,
    excerpt,
    authorName
    slug
}

const PostLarge: React.FC<PostLargeProps> = ({ mainImage, title, lead, excerpt, authorName, slug }) => {
    return (
        <article className="mod-article">
            <div>
                <section role="button" className="mod-media">
                    <figure
                        role="button"
                        className="mod-figure --horizontal"
                    >
                        <Link to={`/${slug}`}>
                            <picture className="mod-picture">
                                <img
                                    src={mainImage.asset.url + '?auto=format'}
                                    alt={mainImage.asset.alt}
                                    width={768}
                                    height={513}
                                    className="com-image"
                                    loading="lazy"
                                />
                            </picture>
                        </Link>
                    </figure>
                </section>
            </div>
            <section className="mod-description">
                <h1 className="com-title --xl">
                    <Link
                        to={`/${slug}`}
                        className="com-link"
                    >
                        <em className="com-lead">
                            {lead}
                        </em>
                        {title}
                    </Link>
                </h1>
                <h2 className="com-subhead --twoxs">
                    <Link
                        to={`/${slug}`}
                    >
                        <BlockContent
                            blocks={excerpt}
                        />
                    </Link>
                </h2>
                <div>
                    <strong className="mod-marquee --fourxs">
                        <Link
                            to={`/${slug}`}
                            title={`Por ${authorName}`}
                        >
                            Por {authorName}
                        </Link>
                    </strong>
                </div>
            </section>
        </article>
    )
}

export default PostLarge
