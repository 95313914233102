import React from 'react'
import { navigate } from "gatsby"

const links = [
    // {
    //     title: 'Coronavirus'
    // },
    // {
    //     title: 'Coronavirus en la Argentina'
    // },
    {
        title: 'La salud de los famosos'
    },
    {
        title: 'Celebridades'
    },
    {
        title: 'Hoy'
    },
    // {
    //     title: 'Alberto Fernández'
    // },
    {
        title: 'Nota de Opinion'
    },
    {
        title: 'A Fondo'
    },
]

const Topics: React.FC = () => {
    const navigateTo = (url: string) => {
        navigate(url)
    }
    return (
        <div className="sidebar__main">
            <div className="acumulado">
                <section className="mod-linklist">
                    <h4 className="com-title --twoxs">Temas del día:</h4>
                    <ul className="com-unordered --tags">
                        {
                            links.map((link, i) => {
                                return (
                                    <li key={i} className="item">
                                        <a
                                            onClick={() => navigateTo('/')}
                                            title={link.title}
                                            className="com-link"
                                        >
                                            {link.title}
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </section>
            </div>
        </div>
    )
}

export default Topics
