import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { graphql } from "gatsby";
import Seo from "../../components/SEO/Seo";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../../services/helpers";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SideMenu from "../../components/SideMenu";
import NavMobile from "../../components/NavMobile";
// import CovidStatus from "../../components/CovidStatus";
import PostsHome from "../../components/Posts/PostsHome";
import Topics from "../../components/Topics";
import SubHeader from "../../components/SubHeader";

const HomeTemplate = ({ data }) => {
  const [scrollClass, setScrollClass] = useState<string>('')

  const seoContent = {
    title: "Últimas noticias de Argentina y el mundo - LA NACION",
    description:
      "Todas las noticias de Argentina y el mundo: últimas noticias en actualidad, deportes, coronavirus, economía, política, y tecnología. Mantenete informado sobre las novedades de Argentina en LA NACION.",
    image:
      "https://res.cloudinary.com/anterux/image/upload/f_auto/v1626304075/diario-digital-clone/placeholderLN.jpg",
    url: ``,
    article: false,
  };

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  useEffect(() => {
    var lastScroll = 0;
    if (typeof window !== `undefined`) {
      window.onscroll = function () {
        let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value

        if (currentScroll > 0 && lastScroll <= currentScroll) {
          lastScroll = currentScroll;
          setScrollClass('--scrollDown');
        } else {
          lastScroll = currentScroll;
          setScrollClass('--scrollUp');
        }
      }
    }
  }, []);

  return (
    <Layout>
      <Seo content={seoContent} />
      <div id="fusion-app">
        <div className="mod-banner --1x1_dsk hlp-none"></div>
        <div className="mod-banner  --1x1_mob hlp-none">
          <div id="1x1_mob" className="com-banner " />
        </div>
        <div id="wrapper" className={`home ${scrollClass}`}>
          <Header />
          <SubHeader />
          <NavMobile />
          <SideMenu />
          {/* <CovidStatus /> */}
          <div className="container --ads">
            <div className="mod-banner --dark --cabezal_dsk" />
          </div>

          <main>
            <div>
              <div id="content-main" className="lay-sidebar">
                <div className="sidebar__main">
                  <PostsHome posts={postNodes} />
                  <div className="mod-banner --billboard_dsk hlp-none">
                    <div id="billboard_dsk" className="com-banner">
                      <img src="https://res.cloudinary.com/anterux/image/upload/f_auto/v1627340110/diario-digital-clone/patagonia-2030.jpg" alt="" width="920" height="250" loading="lazy" />
                    </div>
                  </div>
                </div>
                <div className="sidebar__aside hlp-tabletlm-none">
                  <div className="mod-banner --megalateral --sticky --megalateral_dsk">
                    <img src="https://res.cloudinary.com/anterux/image/upload/f_auto/v1626890230/diario-digital-clone/WhatsApp_Image_2021-07-21_at_14.55.53.jpg" alt="" width="300" height="600" loading="lazy" />
                  </div>
                </div>
              </div>
              <div id="content-main-3" className="lay-sidebar">
                <Topics />
                <div className="sidebar__aside hlp-tabletlm-none" />
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
      query IndexPageQuery {
        posts: allSanityPost(
      limit: 12
      sort: {fields: [publishedAt], order: DESC }
      filter: {slug: {current: {ne: null } }, publishedAt: {ne: null }, enabled: {eq: true} }
      ) {
        edges {
        node {
        id
          slug {
        current
      }
      title
      lead
      _rawExcerpt(resolveReferences: {maxDepth: 5 })

      mainImage {
        asset {
        url
      }
      caption
      alt
          }

      author {
        name
      }

      categories {
        title
      }
        }
      }
    }
  }
      `;

export default HomeTemplate;
